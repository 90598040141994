
#about-me {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
  background-color: white;
  min-height: 100svh;
}

#about-me > h2 {
  align-self: center;
  font-size: 3rem;
  color: var(--accent-color);
  font-family: var(--heading-font);
}

#about-me .box {
  background-color: var(--secondary-color-1);
  border: 10px solid;
  max-width: 60rem;
  align-self: center;
  padding: 1em;
  transform: rotate(-1deg);
  border-color: var(--primary-color);
  margin: 2em 0;
}

#about-me .text-container {
  background-color: var(--primary-color);
  color: white;
  clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 92%);
  padding: 2em 1em;
  transform: rotate(1deg);
}

#about-me .text-container p {
  margin: 1em 0;
}

@media (max-width: 768px) {
  #about-me .text-container {
    padding: 4em 1em;
  }
}


.slider-container {
  --slides-per-view: 4;
  --slides-total: 6;
  width: 100%;
  overflow: hidden;
}

.slider {
  display: flex;
  animation: slide 20s infinite linear;
}

.slide {
  flex: 0 0 calc(100% / var(--slides-per-view));
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% * (var(--slides-total) / var(--slides-per-view))));
  }
}