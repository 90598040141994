
.flip-panel {
  height: 100svh;
  width: 100%;
  position: relative;
  perspective: 1000px;
}

.flip-panel .front,
.flip-panel .back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  transition: transform 1s;
  outline: 1px solid;
}

.flip-panel .front {
  background-color: #ccc;
}

.flip-panel .back {
  /* background-color: #aaa; */
  transform: rotateY(180deg);
  overflow: hidden;
  /* position: absolute; */
}

.flip-panel.flipped .front {
  transform: rotateY(180deg);
}

.flip-panel.flipped .back {
  transform: rotateY(0deg);
  overflow-y: auto;
}


.front {
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 100svh;
  background-position: center top;
  position: relative;
}

.front::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.showcase-section-intro{
  display: flex;
  flex-direction: column;
  gap: 1em;

  background-color: white;
  width: 30em;
  max-width: 95%;
  border: 1px solid;
  padding: 1em;
  border-radius: 8px;
  margin-left: 2em;
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  .showcase-section-intro {
    margin: 0;
    align-self: center;
  }
}

.showcase-section-intro h3 {
  font-family: var(--heading-font);
  color: var(--accent-color);
  font-size: 2rem;
}

.showcase-section-intro-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5em;
}

.project-link {
  color: var(--primary-color);
  text-decoration: underline;
}

.github-link {
  width: 2em;
  position: relative;
}

.code-private {
  opacity: 0.5;
  cursor: not-allowed;
}

.code-private::after {
  content: '';
  background-color: red;
  width: 140%;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(45deg);
  transform-origin: left;
}

.front-intro h3 {
  font-family: var(--heading-font);
  color: var(--accent-color);
  font-size: 2rem;
}

.flip-btn {
  cursor: pointer;
  background-color: var(--secondary-color-1);
  border: none;
  border-radius: 6px;
  padding: .6em;
  box-sizing: border-box;
  color: white;
}

.flip-back-btn {
  cursor: pointer;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  width: 4em;
  /* height: 1em; */
  top: 1em;
  right: 1em;
  position: sticky;
}

.flip-back-btn img {
  object-fit: contain;
  width: 100%;
}

.back {
  display: flex;
  flex-direction: column;
}

.back-content {
  display: flex;
  flex-direction: column;
  gap: 4em;
  padding: 2em;
  padding-bottom: 12em;
  align-self: center;
  max-width: 100%;
  width: 40em;
}

.back h2 {
  color: var(--accent-color);
  font-size: 3rem;
  font-family: var(--heading-font);
}

.back h3 {
  color: var(--accent-color);
  font-size: 2rem;
}

.report-section {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.report-section img {
  max-width: 100%;
  align-self: flex-start;
}

.report-section li {
  list-style: initial;
  margin-bottom: 6px;
}