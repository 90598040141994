
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 4em;
  background-color: var(--primary-color);
}

#footer-copyright {
  align-self: center;
  color: var(--secondary-color-1);
}