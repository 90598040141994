
/* .home {
  display: flex;
  flex-direction: column;
} */

.header {
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  width: 100%;
  background-color: var(--primary-color);
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 0.6em 2em;
  color: white;
  border-bottom: 1px solid white;
  min-height: 3em;
  align-items: center;
}

.navbar-links {
  display: flex;
  gap: 2em;
  font-family: var(--heading-font);
  font-size: 1.6rem;
}

.mobile-navbar {
  display: flex;
  justify-content: space-between;
  padding: 0.6em 2em;
  color: white;
  border-bottom: 1px solid white;
  min-height: 3em;
  align-items: center;
}

.toggle-nav-menu {
  --height: 4px;
  --width: 2em;

  background-color: white;
  height: var(--height);
  width: var(--width);
  position: relative;
}
.toggle-nav-menu::before {
  content: '';
  background-color: white;
  position: absolute;
  height: var(--height);
  width: var(--width);
  top: -.7em;
}
.toggle-nav-menu::after {
  content: '';
  background-color: white;
  position: absolute;
  height: var(--height);
  width: var(--width);
  bottom: -.7em;
}

.mobile-navbar-links {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2em;
  font-family: var(--heading-font);
  font-size: 1.6rem;
  align-items: center;
  top: 0;
  right: -100%;
  z-index: 100000;
  background-color: var(--secondary-color-1);
  width: 100%;
  height: 100%;
  transition: right .5s;
}

.mobile-navbar-active {
  right: 0;
}

.mobile-nav-menu-close {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 1em;
  right: 1em;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-nav-menu-close::before,
.mobile-nav-menu-close::after {
  content: '';
  background-color: white;
  position: absolute;
  width: 100%;
  height: 0.2em;
  transform-origin: center;
}

.mobile-nav-menu-close::before {
  transform: rotate(45deg);
}

.mobile-nav-menu-close::after {
  transform: rotate(-45deg);
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  flex-grow: 1;
  font-family: var(--heading-font);
  color: white;
}

.header-content-container {
  display: flex;
  align-items: center;
  gap: 2em;
  font-size: 3rem;
}

@media (max-width: 900px) {
  .header-content-container {
    flex-direction: column;
    font-size: 2rem;
    margin: .6em;
  }
}

@media (max-width: 500px) {
  .header-content-container {
    font-size: 1.4rem;
  }
}

.header-content span {
  color: var(--secondary-color-1);
}

.header-content-container > img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  /* border-radius: 50%; */
  border: 4px solid transparent;
  box-shadow: 0 0 0 0 #fff;

  animation: borderAnimation 2s infinite alternate;
}

@keyframes borderAnimation {
  to {
    box-shadow: 0 0 0 8px #08f;
  }
}