
.contact-section {
  /* background-color: var(--primary-color); */
  padding: 6em 0;
  text-align: center;
}

.contact-section h2 {
  font-size: 4rem;
  margin-bottom: 1em;
  padding: 0 .2em;
  color: var(--accent-color);
  font-family: var(--heading-font);
}

.contact-section p {
  /* color: white; */
  padding: 0 1em;
}

.contact-section a {
  text-decoration: underline;
}