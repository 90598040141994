#projects {
  text-align: center;
  padding: 6em 0;
  background-color: var(--primary-color);
}

#projects > h2 {
  font-size: 4rem;
  margin-bottom: 1em;
  padding: 0 .2em;
  color: var(--accent-color);
  font-family: var(--heading-font);
}

#projects > p {
  color: white;
  padding: 0 1em;
}

#projects > p > a {
  color: var(--accent-color);
  color: var(--secondary-color-1);
  text-decoration: none;
}