
.smaller-projects {
  background-color: var(--primary-color);
  padding: 6em 0;
}

.smaller-projects h2 {
  color: var(--accent-color);
  text-align: center;
  margin-bottom: 1em;
  font-size: 4rem;
  padding: 0 .2em;
  font-family: var(--heading-font);
}

.smaller-project-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  max-width: 820px;
  padding: 0 10px;
  margin: auto;
}

.smaller-project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 250px;
  max-width: 95%;
  height: 300px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 5px 5px;
  border: 2px solid;
}

.smaller-project-card:hover {
  border: 2px solid var(--secondary-color-1);
}

.smaller-project-card h3 {
  color: var(--accent-color);
  font-size: 2rem;
}

.smaller-project-card p {
  padding: 0 6px;
}

.smaller-project-card a {
  width: 45px;
  align-self: flex-end;
}

.smaller-project-card a>img {
  width: 100%;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.smaller-project-card a>img:hover {
  animation-name: shake;
  animation-duration: .5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.smaller-project-card-tech-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.smaller-project-card-tech-container img {
  height: 36px;
}